<template>
  <section class="not_found">
    <img class="my-4" src="/img/icons/15-not_found.svg" alt="">
    <h3>Esta página no existe.</h3>
    <router-link class="btn-text" to="/dashboard">
      <i class="fas fa-arrow-left mr-2" />Volver al dashboard
    </router-link>
  </section>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.not_found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
}
</style>

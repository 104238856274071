<template>
  <section class="notificaciones">
    <div class="container mt-6">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <h2 class="mb-5">Notificaciones de sonido</h2>
          <div>
            <label class="label mb-1">Nueva conversación pendiente</label>
            <div class="d-flex align-items-center">
              <select v-model="userSounds.n_pending_conversation">
                <option :value="null">Ninguno</option>
                <option v-for="sound in sounds" :key="sound" :value="sound">
                  {{ sound.replace(".mp3", "") }}
                </option>
              </select>
              <button
                class="btn-play"
                :disabled="!userSounds.n_pending_conversation"
                @click="
                  playAudio('/sounds/' + userSounds.n_pending_conversation)
                "><i class="fas fa-play mx-0" />
              </button>
            </div>
            <br />
            <label class="label mb-1">Nueva conversación</label>
            <div class="d-flex">
              <select v-model="userSounds.n_conversation">
                <option :value="null">Ninguno</option>
                <option v-for="sound in sounds" :key="sound" :value="sound">
                  {{ sound.replace(".mp3", "") }}
                </option>
              </select>
              <button
                class="btn-play"
                :disabled="!userSounds.n_conversation"
                @click="playAudio('/sounds/' + userSounds.n_conversation)"
                ><i class="fas fa-play mx-0" />
              </button>
            </div>
            <br />
            <label class="label mb-1">Nuevo mensaje</label>
            <div class="d-flex">
              <select v-model="userSounds.n_databotlive">
                <option :value="null">Ninguno</option>
                <option v-for="sound in sounds" :key="sound" :value="sound">
                  {{ sound.replace(".mp3", "") }}
                </option>
              </select>
              <button
                class="btn-play"
                :disabled="!userSounds.n_databotlive"
                @click="playAudio('/sounds/' + userSounds.n_databotlive)"
                ><i class="fas fa-play mx-0" />
              </button>
            </div>
            <br />
            <label class="label mb-1">Nuevo lead</label>
            <div class="d-flex">
              <select v-model="userSounds.n_leads">
                <option :value="null">Ninguno</option>
                <option v-for="sound in sounds" :key="sound" :value="sound">
                  {{ sound.replace(".mp3", "") }}
                </option>
              </select>
              <button
                class="btn-play"
                :disabled="!userSounds.n_leads"
                @click="playAudio('/sounds/' + userSounds.n_leads)"
              >
                <i class="fas fa-play mx-0" />
              </button>
            </div>
            <br />
            <div class="row d-flex aling-items-center my-5">
              <button
                type="button"
                class="btn btn-base"
                @click="save(userSounds)"
                >Guardar cambios
              </button>
              <p class="info-save" v-show="saved">¡Cambios guardados!</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-2">
          <div class="card buefy">
            <div class="d-flex justify-content-between">
              <div class="col-lg-8 pl-0">
                <label>
                  Notificaciones de correo electrónico cuando se registra un nuevo Lead
                </label>
              </div>
              <div class="col-lg-4 d-flex justify-content-end align-items-center px-0">
                <b-switch v-model="user.get_email" />
              </div>
            </div>
            <p>Esta opción permite activar o desactivar las notificaciones por correo electrónico de su cuenta de Databot.</p>
          </div>
          <div class="card buefy">
            <div class="d-flex justify-content-between">
              <div class="col-lg-8 pl-0">
                <label class="mb-0">Notificaciones de sonido</label>
              </div>
              <div class="col-lg-4 d-flex justify-content-end align-items-center px-0">
                <b-switch v-model="userSounds.has_sound" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3 mb-5 justify-content-center">
        <div class="col-lg-6">
          <router-link class="btn-text px-2" to="/dashboard">
            <i class="fas fa-arrow-left mr-2" />Volver</router-link>
        </div>
        <div class="col-lg-4">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import '../assets/buefy_class.scss';
import dashboard_api from '../dashboard_api';
import dashboard_api_v2 from "@/dashboard_api_v2";

export default {
  data() {
    return {
      isSwitched: false,
      isSwitchedCustom: 'Yes',
      saved: false,
      userSounds: {
        n_pending_conversation: null,
        n_conversation: null,
        n_databotlive: null,
        n_leads: null,        
        has_sound: true,
      },
      user: {},
      sounds: [
        'badumtss.mp3',
        'juntos.mp3',
        'percussion.mp3',
        'pieceofcake.mp3',
        'pristine.mp3',
        'thatwasquick.mp3',
        'when.mp3',
      ],
    };
  },
  async mounted() {
    const userId = this.$store.state.user.id;
    this.user = (await dashboard_api.get(`/relief/user?user_id=${userId}`)).data
    dashboard_api
      .get(`/user_notification/list/${userId}`)
      .then((response) => {
        if (response.data) {
          this.userSounds = response.data;
        }
      })
      .catch((err) => console.log('error: ', err));
  },
  methods: {
    playAudio(path) {
      const audio = new Audio(path);
      audio.play();
    },
    save(userSounds) {
      const userId = this.$store.state.user.id;
      // guarda switch notificación de correo
      dashboard_api_v2
        .put("client/update_email", {
          client: {
            email: this.user.email,
            get_email: this.user.get_email
          }
        })
        .catch((err) => console.log('error: ', err));        
      if (userSounds.hasOwnProperty('id')) {
        // actualizar
        dashboard_api
          .put(`/user_notification/put/${userId}`, { ...userSounds })
          .then((response) => {
            if (response.data) {
              this.userSounds = response.data;
              this.saveToLocalStorage(this.userSounds);
            }
          })
          .catch((err) => console.log('error: ', err));
      } else {
        // crear
        dashboard_api
          .post('/user_notification/post/', { ...userSounds, user_id: userId })
          .then((response) => {
            if (response.data) {
              this.userSounds = response.data;
              this.saveToLocalStorage(this.userSounds);
            }
          })
          .catch((err) => console.log('error: ', err));
      }
      this.saved = true;
    },
    saveToLocalStorage(userSounds) {
      localStorage.setItem('userSounds', JSON.stringify(userSounds));
    },
  },
};
</script>

<style lang="scss" scoped>
.notificaciones {
  .card {
    padding: 1.5rem;
    margin-bottom: 1.5rem;
  }
  select {
    width: 100%;
    margin-bottom: 0;
  }
  .info-save {
    margin: auto 0;
  }
  .btn-play {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    border-radius: 100%;
    background-color: #181818;
    border: 1px solid #333;
    padding: 1.25rem;
    transition: .5s;

    &:hover {
      background-color: #2981EF;
      border: 1px solid #2981EF;
    }
    i {
      color: #fff;
      font-size: .75rem;
      margin-left: .2rem;
    }
    &:disabled {
      background-color: #b2b2b2;
      border: 1px solid #b2b2b2;
    }
  }
}
</style>

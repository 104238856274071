import { render, staticRenderFns } from "./DashboardEcommerce.vue?vue&type=template&id=14f31690&scoped=true"
import script from "./DashboardEcommerce.vue?vue&type=script&lang=js"
export * from "./DashboardEcommerce.vue?vue&type=script&lang=js"
import style0 from "./DashboardEcommerce.vue?vue&type=style&index=0&id=14f31690&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14f31690",
  null
  
)

export default component.exports